import {
  add_query,
  get_query,
  get_carousel_image,
  get_deal_image,
  get_brand_image,
  FusionId,
  get_sell_rights_step,
  get_media_mentions,
  get_endorsements,
  get_faq,
  get_video_link,
  get_allaince_creds,
  get_content_buyers,
  get_stack_cards,
  get_blogs,
} from "../BaseAPI/API";
import { apiPOSTRequest } from "../apiRequest";

const fetch_get_query = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_query, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_query = async (
  names,
  email,
  subject,
  number,
  QueryMsg,
  productionHouse
) => {
  const sendData = new FormData();
  sendData.append("names", names);
  sendData.append("emailId", email);
  sendData.append("subjects", subject);
  sendData.append("mobileNo", number);
  sendData.append("messages", QueryMsg);
  sendData.append("productionHouse", productionHouse);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(add_query, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_carousel_image = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_carousel_image, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_deal_image = async (imageId) => {
  const sendData = new FormData();
  sendData.append("imageId", imageId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_deal_image, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_brand_image = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_brand_image, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

//stepbystep

const fetch_step_by_step = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_sell_rights_step, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

//media_mentions
const fetch_Media_mentions = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_media_mentions, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

//get_endorsements
const fetch_endorsements = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_endorsements, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

//get_FAQ

const fetch_FAQ = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_faq, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_Video_Link = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_video_link, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_allaince_creds = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_allaince_creds, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_content_buyers = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_content_buyers, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_stack_cards = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  try {
    const responseData = await apiPOSTRequest(get_stack_cards, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_blogs = async (id) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", 1);
  sendData.append("blogId", id);
  try {
    const responseData = await apiPOSTRequest(get_blogs, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  fetch_get_query,
  fetch_get_blogs,
  fetch_get_brand_image,
  fetch_get_stack_cards,
  fetch_get_allaince_creds,
  fetch_get_content_buyers,
  fetch_get_deal_image,
  fetch_add_query,
  fetch_get_carousel_image,
  fetch_Video_Link,
  fetch_FAQ,
  fetch_Media_mentions,
  fetch_endorsements,
  fetch_step_by_step,
};
