import React, { createContext, useEffect, useState } from "react";
import { fetch_get_users } from "../Utils/Services/UserService";

// Creating a context object
export const UserContext = createContext();

// A provider component to wrap your application and provide the context value
export const UserProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const userId = localStorage.getItem("BazaarUserId");

  const getUserData = async () => {
    try {
      const responseData = await fetch_get_users(userId);
      if (!responseData.error) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getUserData();
  }, [userId]);

  return (
    <UserContext.Provider value={{ data, setData, getUserData }}>
      {children}
    </UserContext.Provider>
  );
};
