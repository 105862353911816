import {
  verify_email_otp,
  send_email_otp,
  get_countries,
  get_states,
  create_order,
  send_payment_success_mail,
  verify_signature,
  get_languages,
  get_genres,
  FusionId,
  generate_payment_receipt,
} from "../BaseAPI/API";
import { apiPOSTRequest } from "../apiRequest";

const fetch_verify_email_otp = async (email, otp) => {
  const sendData = new FormData();
  sendData.append("email", email);
  sendData.append("otpNumber", otp);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(verify_email_otp, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_send_email_otp = async (email) => {
  const sendData = new FormData();
  sendData.append("email", email);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(send_email_otp, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_countries = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_countries, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_languages = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_languages, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_genres = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_genres, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_states = async (countryId) => {
  const sendData = new FormData();
  sendData.append("countryId", countryId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_states, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_send_payment_success_mail = async (email, order, name) => {
  const sendData = new FormData();
  sendData.append("userMail", email);
  sendData.append("orderId", order);
  sendData.append("userName", name);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(
      send_payment_success_mail,
      sendData
    );
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_generate_payment_receipt = async (
  paymentStatus,
  orderId,
  sellerId,
  paymentId,
  saveType
) => {
  const sendData = new FormData();
  sendData.append("paymentStatus", paymentStatus);
  sendData.append("orderId", orderId);
  sendData.append("sellerId", sellerId);
  sendData.append("paymentId", paymentId);
  sendData.append("saveType", saveType);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(
      generate_payment_receipt,
      sendData
    );
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_create_order = async (
  Name,
  email,
  password,
  productionHouse,
  number,
  usertype,
  streetAddress1,
  streetAddress2,
  city,
  stateName,
  postalCode,
  countryName,
  movieTitle,
  gstr,
  amount,
  productionLogo,
  isMobileVerified,
  isMailVerified
) => {
  const sendData = new FormData();
  sendData.append("userName", Name);
  sendData.append("userMail", email);
  sendData.append("userPassword", password);
  sendData.append("productionName", productionHouse);
  sendData.append("userMobile", number);
  sendData.append("userType", usertype);
  sendData.append("contactAddress1", streetAddress1);
  sendData.append("contactAddress2", streetAddress2);
  sendData.append("cityName", city);
  sendData.append("stateName", stateName);
  sendData.append("postalCode", postalCode);
  sendData.append("countryName", countryName);
  sendData.append("movieTitle", movieTitle);
  sendData.append("GSTRNo", gstr);
  sendData.append("amount", amount);
  sendData.append("productionLogo", productionLogo);
  sendData.append("isMobileVerified", isMobileVerified);
  sendData.append("isMailVerified", isMailVerified);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(create_order, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_verify_signature = async (
  orderId,
  paymentId,
  signature,
  paymentStatus,
  savedType,
  sellerId
) => {
  const sendData = new FormData();
  sendData.append("orderId", orderId);
  sendData.append("paymentId", paymentId);
  sendData.append("signatures", signature);
  sendData.append("paymentStatus", paymentStatus);
  sendData.append("saveType", savedType);
  sendData.append("sellerId", sellerId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(verify_signature, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  fetch_get_genres,
  fetch_get_languages,
  fetch_verify_email_otp,
  fetch_verify_signature,
  fetch_create_order,
  fetch_send_payment_success_mail,
  fetch_get_states,
  fetch_get_countries,
  fetch_send_email_otp,
  fetch_generate_payment_receipt,
};
