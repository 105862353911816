import axios from "axios";

export const apiGETRequest = async (method, url) => {
  try {
    const response = await axios({
      method: method,
      url: url,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const apiPOSTRequest = async (url, data) => {
  try {
    const response = await axios({
      method: "POST",
      url: url,
      data: data,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
