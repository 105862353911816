import {
  update_user_profile,
  user_sign_in,
  user_sign_up,
  get_users,
  delete_user,
  get_user_wishlist,
  add_to_user_wishlist,
  FusionId,
  user_google_sign_in,
} from "../BaseAPI/API";
import { apiPOSTRequest } from "../apiRequest";

const fetch_update_user_profile = async (userId, profilePic) => {
  const sendData = new FormData();
  sendData.append("userId", userId);
  sendData.append("userProfile", profilePic);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(update_user_profile, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_user_sign_in = async (email, password) => {
  const sendData = new FormData();
  sendData.append("userMail", email);
  sendData.append("userPassword", password);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(user_sign_in, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_user_sign_up = async (
  Name,
  email,
  password,
  productionHouse,
  number,
  usertype,
  streetAddress1,
  streetAddress2,
  city,
  stateName,
  postalCode,
  countryName,
  movieTitle,
  gstr,
  amount,
  productionLogo,
  isMobileVerified,
  isMailVerified
) => {
  const sendData = new FormData();
  sendData.append("userName", Name);
  sendData.append("userMail", email);
  sendData.append("userPassword", password);
  sendData.append("productionName", productionHouse);
  sendData.append("userMobile", number);
  sendData.append("userType", usertype);
  sendData.append("contactAddress1", streetAddress1);
  sendData.append("contactAddress2", streetAddress2);
  sendData.append("cityName", city);
  sendData.append("stateName", stateName);
  sendData.append("postalCode", postalCode);
  sendData.append("countryName", countryName);
  sendData.append("movieTitle", movieTitle);
  sendData.append("GSTRNo", gstr);
  sendData.append("amount", amount);
  sendData.append("productionLogo", productionLogo);
  sendData.append("isMobileVerified", isMobileVerified);
  sendData.append("isMailVerified", isMailVerified);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(user_sign_up, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_users = async (userId) => {
  const sendData = new FormData();
  sendData.append("userId", userId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_users, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_delete_user = async (sellerId) => {
  const sendData = new FormData();
  sendData.append("sellerId", sellerId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(delete_user, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_user_wishlist = async (userId) => {
  const sendData = new FormData();
  sendData.append("userId", userId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_user_wishlist, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_to_user_wishlist = async (movId, userId, rigId, wish) => {
  const sendData = new FormData();
  sendData.append("movieId", movId);
  sendData.append("userId", userId);
  sendData.append("rightsId", rigId);
  sendData.append("wishlist", wish);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(add_to_user_wishlist, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_user_google_sign_in = async (email) => {
  const sendData = new FormData();
  sendData.append("userMail", email);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(user_google_sign_in, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  fetch_update_user_profile,
  fetch_user_google_sign_in,
  fetch_get_users,
  fetch_user_sign_in,
  fetch_user_sign_up,
  fetch_delete_user,
  fetch_add_to_user_wishlist,
  fetch_get_user_wishlist,
};
