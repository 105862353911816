import Poster1 from "../Assets/HomeSlider/poster1.jpg";
import Poster2 from "../Assets/HomeSlider/poster2.jpg";
import Poster3 from "../Assets/HomeSlider/poster3.jpg";
import Poster4 from "../Assets/HomeSlider/poster4.jpg";
import Poster5 from "../Assets/HomeSlider/poster5.jpg";

import Testimonial1 from "../Assets/TEstimonial Faces/Ms.Raja Jastina.jpeg";
import Testimonial2 from "../Assets/TEstimonial Faces/Mr.Suresh.jpeg";
import Testimonial3 from "../Assets/TEstimonial Faces/Ms. Svetlana Naudiyal.jpeg";
import Testimonial4 from "../Assets/TEstimonial Faces/Ms.Raja Jastina.jpeg";
import Testimonial5 from "../Assets/TEstimonial Faces/Ms.Raja Jastina.jpeg";
import Testimonial6 from "../Assets/TEstimonial Faces/supriya.jpg";
import Testimonial7 from "../Assets/TEstimonial Faces/sharrath.jpg";
import Testimonial8 from "../Assets/TEstimonial Faces/Girish Kasaravalli.jpeg";

import ProductionHouse1 from "../Assets/ProductionHouse/1.png";
import ProductionHouse2 from "../Assets/ProductionHouse/2.png";
import ProductionHouse3 from "../Assets/ProductionHouse/3.jpeg";
import ProductionHouse4 from "../Assets/ProductionHouse/4.png";
import ProductionHouse5 from "../Assets/ProductionHouse/5.png";

import Event1 from "../Assets/Events/Kerala Film Producers Association.jpg";
import Event2 from "../Assets/Events/Research Book Release.jpg";
import Event3 from "../Assets/Events/Startup Network DUBAI.jpg";
import Event4 from "../Assets/Events/Tamilnadu Active Film Producers Association.png";
import Event5 from "../Assets/Events/Telugu Film Chamber of Commerce.png";
import Event6 from "../Assets/Events/VPS_0332.jpg";
import Event7 from "../Assets/Events/IIFA.jpg";

import Media1 from "../Assets/Media/medai1.PNG";
import Media2 from "../Assets/Media/media2.PNG";
import Media3 from "../Assets/Media/media3.PNG";
import Media4 from "../Assets/Media/media4.PNG";
import Media5 from "../Assets/Media/media5.PNG";
import Media6 from "../Assets/Media/media6.PNG";
import Media7 from "../Assets/Media/media7.PNG";
import Media8 from "../Assets/Media/media8.PNG";
import Media9 from "../Assets/Media/media9.PNG";

import Deal1 from "../Assets//DealClosedMovie/Night Drive.jpg";
import Deal2 from "../Assets//DealClosedMovie/Space Walker.jpg";
import Deal3 from "../Assets//DealClosedMovie/Heroic Losers.jpg";
import Deal4 from "../Assets//DealClosedMovie/The Heist of the century.jpg";
import Deal5 from "../Assets//DealClosedMovie/The girl with a bracelet.jpg";
import Deal6 from "../Assets//DealClosedMovie/Shorta.jpg";
import Deal7 from "../Assets//DealClosedMovie/Run Hide Fight.jpg";
import Deal8 from "../Assets//DealClosedMovie/I remember you.jpg";
import Deal9 from "../Assets//DealClosedMovie/The Sunlit Night.jpg";
import Deal10 from "../Assets//DealClosedMovie/The Tunnel.jpg";
import Deal11 from "../Assets//DealClosedMovie/Fear of Rain.jpg";
import Deal12 from "../Assets//DealClosedMovie/Martyrs Lane.jpg";
import Deal13 from "../Assets//DealClosedMovie/Malasana 32.jpg";
import Deal14 from "../Assets//DealClosedMovie/How to make out.jpg";
import Deal15 from "../Assets//DealClosedMovie/Gatecrash.jpg";
import Deal16 from "../Assets//DealClosedMovie/assassin.jpg"; //assassins
import Deal17 from "../Assets//DealClosedMovie/The Hating Game.jpg";
import Deal18 from "../Assets//DealClosedMovie/THROUGH THE GLASS DARKLY.jpg";
import Deal19 from "../Assets//DealClosedMovie/INTO THE DARKNESS.jpg";
import Deal20 from "../Assets//DealClosedMovie/BIGGEST FAN.jpg";
import Deal21 from "../Assets//DealClosedMovie/The Trip.jpg";
import Deal22 from "../Assets//DealClosedMovie/Maayavan.jpg";
import Deal23 from "../Assets//DealClosedMovie/vaaral.jpg";
import Deal24 from "../Assets//DealClosedMovie/Bhanumathi and Ramakrishna.jpg";
import Deal25 from "../Assets//DealClosedMovie/emoji.jpg";
import Deal26 from "../Assets//DealClosedMovie/Three Roses.jpg";
import Deal27 from "../Assets//DealClosedMovie/Shit Happens.jpg";
import Deal28 from "../Assets//DealClosedMovie/11th hour.webp";

import betterInvest from "../Assets/Alliance Creds/betterinvestclub_logo.jpeg";
import gaurdainlink from "../Assets/Alliance Creds/guardianlink-black-logo.png";
import kirlaw from "../Assets/Alliance Creds/kirlaw.png";
import brandchange from "../Assets/Alliance Creds/logo-light.png";
import startup from "../Assets/Alliance Creds/startup-india-removebg-preview.png";

import Jio from "../Assets/Content Buyers/2-173.jpg";
import Aha from "../Assets/Content Buyers/Aha_OTT_Logo.svg.png";
import Prime from "../Assets/Content Buyers/Amazon_Prime_Video_logo.svg.png";
import Disney from "../Assets/Content Buyers/Disney-Hotstar.png";
import Book from "../Assets/Content Buyers/bookmyshow.png";
import Bongo from "../Assets/Content Buyers/Bongo_BD_logo.png";
import Zee5 from "../Assets/Content Buyers/zee53067.jpg";

import BlogImage from "../Assets/Blog.jpg";

const slickImages = [
  {
    images: Poster1,
  },

  {
    images: Poster2,
  },

  {
    images: Poster3,
  },
  {
    images: Poster4,
  },

  {
    images: Poster5,
  },
  {
    images: Poster1,
  },
  {
    images: Poster2,
  },

  {
    images: Poster3,
  },
  {
    images: Poster4,
  },
];

const slickImages1 = [
  {
    key: 1,
    content: <img src={Poster1} alt="1" />,
  },
  {
    key: 2,
    content: <img src={Poster2} alt="2" />,
  },

  {
    key: 3,
    content: <img src={Poster3} alt="3" />,
  },

  {
    key: 4,
    content: <img src={Poster4} alt="4" />,
  },
  {
    key: 5,
    content: <img src={Poster5} alt="5" />,
  },
  {
    key: 6,
    content: <img src={Poster1} alt="1" />,
  },
  {
    key: 7,
    content: <img src={Poster2} alt="2" />,
  },
  {
    key: 8,
    content: <img src={Poster3} alt="3" />,
  },
];

const productionHouses = [
  {
    images: ProductionHouse1,
  },
  {
    images: ProductionHouse2,
  },
  {
    images: ProductionHouse3,
  },
  {
    images: ProductionHouse4,
  },
  {
    images: ProductionHouse5,
  },
  {
    images: ProductionHouse1,
  },
  {
    images: ProductionHouse2,
  },
];

const testimonials = [
  // {
  //     description:`"Legal issues in content purchase, renting and leasing are one thing which is not streamlined. Taking all issues to courts is leading to stress and time consuming. There should be some standards like ISO for movies and producers also."`,
  //     name: 'Ms. Raja Jastina',
  //     role: 'Vice President Astro Television, Malaysia',
  //     image: Testimonial1,
  //   },
  {
    description: `"I have been into this industry for 25+ years as a producer, distributor and director. I strongly feel technology should disrupt our industry and change the way business is one. Producerbazaar.com looks promising disrupter for Media Industry."`,
    name: "Mr.Suresh Kumar",
    role: "President, Kerala Film Chamber of Commerce.",
    image: Testimonial2,
  },
  // {
  //   description:`"Indian Population has huge attachment for movies. “Watching movies” is the only form of entertainment for good amount of crowd which also need transparent business transactions to improve this industry to international standards"`,
  //   name: 'Ms. Svetlana Naudiyal',
  //   role: 'Programing Director, Mubi Asia.',
  //   image: Testimonial3,
  // },

  // {
  //   description:`"As a creator I always felt should have moved to western countries for producing albums where I tend to get long term benefit for my albums but in India the trend is too short term."`,
  //   name: 'Mr. M Ghibran',
  //   role: 'Indian Composer',
  //   image: Testimonial4,
  // },

  // {
  //   description:`"Producerbazaar.com a centralised b2b platform helps to enhance the creator ecosystem acts catalyst   between the Producer – Broadcaster and End Audience this will help to  improve this industry to international standards"`,
  //   name: 'Mr. CV Kumar',
  //   role: 'Award Winning Indian Film Producer',
  //   image: Testimonial5,
  // },
  {
    description: `"So far we all have been focussing on producing good content as we are basically filmmakers and we get exhausted to ready 30-page agreements. Now as a producer we have to focus on 360 degree monetisation. Thank you GK and Vijay for opening up the box which we are passionate about but very scared to open it up all these days. Now we have Producerbazzar.com to track our rights when we focus on filmmaking."`,
    name: "Ms. Supriya Yalagadda",
    role: "Secretary, Active Film Producers Guild",
    image: Testimonial6,
  },
  {
    description: `"All these days we have been unknowingly talking about linear and non-linear, close the agreement, proceed to release, focus on success, and then move on to next movie. But now we are at Film Business 3.0 as Mr. GK Tirunavukarasu and Mr. Vijay explained the changing dynamics of film business and markets are maturing to break rights separately and see how we can monetise that."`,
    name: "Mr. Sharrath Marar",
    role: "Film Producer and Entrepreneur",
    image: Testimonial7,
  },

  {
    description: `"Producerbazaar.com a centralised b2b platform helps to enhance the creator ecosystem acts catalyst between the Producer – Broadcaster and End Audience this will help to  improve this industry to international standards"`,
    name: "Mr. Girish Kasaravalli",
    role: "Kannada Film Director",
    image: Testimonial8,
  },
];

const HomeCards = [
  {
    image: Poster1,
    owner: "Tanya Chand",
    movie: "Irul",
    right: "Audio",
    price: "₹15,000",
  },
  {
    image: Poster2,
    owner: "Billy Butcher",
    movie: "The Boys",
    right: "Making",
    price: "₹15,000",
  },
  {
    image: Poster3,
    owner: "Jordan Li",
    movie: "Gen V",
    right: "NFT",
    price: "₹15,000",
  },
  {
    image: Poster4,
    owner: "Homelander",
    movie: "The Boys",
    right: "Satellite",
    price: "₹15,000",
  },
  {
    image: Poster5,
    owner: "WanHeda",
    movie: "The 100",
    right: "Digital",
    price: "₹15,000",
  },
  {
    image: Poster2,
    owner: "Billy Butcher",
    movie: "The Boys",
    right: "Making",
    price: "₹15,000",
  },
];

const rightList = [
  {
    name: "Audio",
    right: "Audio",
  },
  {
    name: "Satellite",
    right: "Satellite",
  },
  {
    name: "Digital",
    right: "Digital",
  },
  {
    name: "Making",
    right: "Making",
  },
  {
    name: "Remake",
    right: "Remake",
  },
  {
    name: "Theatrical",
    right: "Theatrical",
  },
];

const BuyerList = [
  {
    name: "Collections",
  },
  {
    name: "Transactions",
  },
  {
    name: "Liked",
  },
];

const sellerList = [
  {
    name: "Saved Works",
  },
  {
    name: "Uploads",
  },
];

const FAQ = [
  {
    title: "How can I register as a Buyer? ",
    content: `To register as a Buyer, simply sign up, verify your email, and create your user and company profiles as instructed. Our team ensures legitimacy by vetting Buyer companies. You'll be notified once your Buyer status is approved. If you have a screening invitation, you can start by creating a username and password to access the screeners immediately.`,
  },
  {
    title: `Who qualifies to register as a Seller? `,
    content: `Sellers eligible for registration are producers with individual or multiple movie titles. Preference is granted to titles possessing a social media presence, copyrighted content, and a censor certificate.`,
  },
  {
    title: `Can I have multiple accounts on Producerbazaar? `,
    content: `Yes, if you represent different companies. Just use different email addresses and register under separate organizations. Occasionally, you might need to explain why you need a second account. This helps ensure a trusted community for both buyers and sellers on Producerbazaar.`,
  },
  {
    title: `How can Sellers connect with Buyers on the platform?`,
    content: `Sellers can connect with Buyers by listing their content's availability for each title. Buyers actively search for content based on rights availability by territory, and upon finding the content, they can directly contact the Seller for screeners or inquiries. Additionally, our team facilitates matching Sellers with relevant Buyers across 15 countries, expanding the reach of their content.`,
  },
  {
    title: `Does ProducerBazaar offer support for copyrighting content?`,
    content: `Yes, ProducerBazaar provides copyrighting services at an additional cost, tailored to specific needs. The pricing varies based on the exact requirements.`,
  },

  {
    title: `Is there a charge associated with becoming a Buyer on Producerbazaar? `,
    content: `No, there are no costs involved in registering as a Buyer on Producerbazaar. The platform offers free registration for Buyers, providing access to its features and services without any fees.`,
  },
  {
    title: `Are there any expenses for becoming a Seller on this platform? `,
    content: `Signing up as a Seller on our platform is completely free. However, there's a nominal fee associated with uploading your content catalogue and accessing the Dashboard functionalities.`,
  },
  {
    title: `How does Producerbazaar.com manage and track content titles being traded on its platform? `,
    content: `Producerbazaar.com utilizes Blockchain technology and smart contracts to access and track all content titles traded on the platform, ensuring secure and transparent transactions.`,
  },
];

const Events = [
  {
    images: Event1,
    date: 2024 - 11 - 11,
    title:
      "Producerbazaar expands its wings in Kerala with 40+ Malayalam producers",
    content: `Producerbazaar, a Chennai-based NFT Movie Market Place, has teamed up with 40+ producers in Kerala, led by Mr. G Suresh Kumar, President of the Kerala Film Chamber of Commerce. This partnership aims to globally distribute Malayalam films digitally, ensuring secure transactions and increased revenue. KFCC President Mr. Suresh Kumar encouraged more events in Kerala, supported by industry figures like Mr. G S Vijayan and Mr. Kalliyoor Sasi.
    With 1000+ movies already onboarded, Producerbazaar plans to expand, using NFT technology to bring international films to India and create new revenue streams for production houses. This aligns with emerging entertainment concepts like Blockchain and Movieindustry 3.0, reshaping the future of cinema.`,
  },
  {
    images: Event2,
    date: 2024 - 11 - 11,
    title: `NFDC hires NFT Creator Economy to evaluate Tamil film industry's status & potential`,
    content: `NFDC, under Director Rajesh Khanna's leadership, has commissioned a survey through NFT Creator Economy Pvt. Ltd, undertaken by Producerbazaar, India's pioneering NFT Movie marketplace, to assess the status and potential of the Tamil film industry. The initiative involves engaging stakeholders—Producers, Distributors, and Exhibitors—to gather insights through a structured questionnaire, intending to compile a comprehensive report. The report aims to evaluate the industry's health, identify revenue leaks, catalog films in production, uncover quality unfinished projects, and strengthen Tamil cinema. Rajesh Khanna emphasized producer support, acknowledging their role as the industry's backbone. G K Tirunavukarasu, Co-Founder of Producerbazaar, highlighted the initiative's potential to boost revenue and industry development, while Senthil Nayagam emphasized technology's pivotal role in tracking movie releases and enhancing revenue within the Tamil film industry.`,
  },
  {
    images: Event3,
    date: 2024 - 11 - 11,
    title: "Overseas Recognition through Startup MiddleEast",
    content: `In the vibrant atmosphere of the 2023 Startup Middle East's PITCH Session, ProducerBazaar emerged as a standout force, captivating a global audience of top-tier startups with its pioneering vision. This pivotal event became an invaluable platform, garnering priceless feedback and sparking intense interest from investors worldwide. Amidst dynamic presentations, ProducerBazaar's innovative approach resonated deeply, earning accolades and affirming its potential to transcend borders, setting the stage for a global footprint.
    The profound impact of this session was twofold: the invaluable feedback received underscored the strength of ProducerBazaar's unique vision, while the burgeoning investor interest served as a resounding validation of our robust business model. This event now stands as an indelible milestone in our journey, fueling our trajectory towards a future illuminated by boundless opportunities. It reaffirms our commitment to innovation and positions ProducerBazaar on a trajectory to make a resounding global impact.`,
  },
  {
    images: Event4,
    date: 2024 - 11 - 11,
    title: "Film producers gets one more avenue to generate revenue",
    content: `Producerbazaar.com, in partnership with BetterInvest.club, introduces a novel funding initiative for production houses based on sale contracts with OTTs, Audio labels, Web Series, Tele Serials, and Satellite TV Networks. These entities often pay in installments, causing financial strain on production houses needing immediate funds to complete ongoing projects. To address this, Producerbazaar.com collaborates with BetterInvest.club, providing swift financing within 7 to 10 working days for production houses that have signed rights agreements without requiring collateral. This initiative aims to alleviate financial constraints faced by producers, enabling better project management and timely monetization. With the service extended to languages like Telugu, Malayalam, Kannada, and Marathi, including the financing of 4 Tamil movies, BetterInvest.club and Producerbazaar.com aim to assist production houses effectively manage their working capital needs.`,
  },
  {
    images: Event5,
    date: 2024 - 11 - 11,
    title: "Pathbreaking Event at Hyderabad with 100+ Producers",
    content: `100+ Telugu film producers joined a groundbreaking session on 'Copyrights & IP Rights in Cinema' by Producerbazaar.com and the Telugu Film Chamber of Commerce. The event, attended by industry stalwarts, aimed to propel the Telugu film industry into the digital era, emphasizing comprehensive monetization through innovative rights management. Producerbazaar.com, India's leading media tech company, offers solutions to track and monetize film rights, revolutionizing the industry's approach to content creation and commercialization.`,
  },
  {
    images: Event6,
    date: 2024 - 11 - 11,
    title: "Bengaluru event: Learn to monetize and protect films.",
    content: `ProducerBazaar.com recently hosted an IPR Monetizing Strategic Event in Bengaluru on December 7, aiming to educate and empower content creators in effectively positioning and safeguarding their work. The event, held at Dr. Ambareesh Auditorium, Chamrajpet, featured insights from industry pioneers like Girish Kasaravalli, focusing on essential aspects like funding, IP rights protection, and innovative content strategies. Senthil Nayagam, CEO of NFT Creator Economy Private Limited (NCEPL), emphasized their goal of creating a transparent media business ecosystem connecting artists and end users through their platform. With over 2000 film creators' trust and plans to introduce a B2B Marketplace and enter the Metaverse, ProducerBazaar.com aims to revolutionize content trade using NFT and Blockchain technology, as highlighted by Co-Founder GK Tirunavukarasu and supported by perspectives from Angel Investor Mr. Varada Rajan Krishna and IPR attorney Jacob Kurian, emphasizing the event's focus on securing and monetizing creators' stories.`,
  },
  {
    images: Event7,
    date: 2024 - 11 - 11,
    title: "Recognition from Goa Government in IFFI GOA 2023",
    content: `Producerbazaar, a visionary in reshaping the entertainment industry, received a pivotal nod by being selected to showcase at the prestigious Tech Media Startup Expo 2023 during the International Film Festival of India (#IFFI) Goa. This recognition at IFFI Goa fuels their commitment to redefine media tech. Previously, they participated in GOA TECH SUMMIT, led a session at Startup Goa, and fostered valuable connections, including insightful discussions on future strategies. Their enthusiasm was evident in reconnecting with past contacts and acknowledging industry dedication, culminating in a collective effort toward Goa's startup growth.`,
  },
];

const mediaMentions = [
  {
    image: Media1,
    link: "https://www.entrepreneur.com/en-in/entrepreneurs/do-you-know-about-indias-first-nft-movie-marketplace/446520",
    title: "Entrepreneur India",
    date: "FEB 27, 2023",
  },
  {
    image: Media2,
    link: "https://rollingstoneindia.com/indian-entertainment-industry-updates-hoopr-roposo-play-dmf-producers-bazaar/",
    title: "Rolling Stone India",
    date: "FEB 27, 2023",
  },
  {
    image: Media3,
    link: "https://www.eastmojo.com/technology/2023/02/15/5-future-forward-indian-startups-that-are-using-web-3-0/",
    title: "EastMojo",
    date: "FEB 27, 2023",
  },
  {
    image: Media4,
    link: "https://flipboard.com/article/do-you-know-about-india-s-first-nft-movie-marketplace-entrepreneur/f-6976c6fd9b%2Fentrepreneur.com",
    title: "FLIPBOARD",
    date: "FEB 27, 2023",
  },
  {
    image: Media5,
    link: "https://etinsights.et-edge.com/film-business-3-0-a-shift-from-a-transactional-to-a-transformational-model/",
    title: "Insights",
    date: "FEB 27, 2023",
  },
  {
    image: Media6,
    link: "https://www.firstpost.com/opinion/five-mistakes-film-producers-should-avoid-12215022.html",
    title: "Firstpost",
    date: "FEB 27, 2023",
  },
  {
    image: Media7,
    link: "https://cxotoday.com/cxo-bytes/how-will-5g-impact-web-3-0-and-the-content-marketing-business/",
    title: "Cxotoday",
    date: "FEB 27, 2023",
  },
  {
    image: Media8,
    link: "https://biz.crast.net/how-will-5g-affect-web-3-0-and-the-content-marketing-business/",
    title: "Biz.crast",
    date: "FEB 27, 2023",
  },
  {
    image: Media9,
    link: "https://www.business-standard.com/article/technology/a-marketplace-to-unlock-value-for-small-unseen-interesting-films-123022801284_1.html",
    title: "Business-Standard",
    date: "FEB 27, 2023",
  },
];

const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const genreOptions = [
  "Action",
  "Adventure",
  "Animation",
  "Biography",
  "Comedy",
  "Crime",
  "Documentary",
  "Drama",
  "Family",
  "Fantasy",
  "Film Noir",
  "History",
  "Horror",
  "Music",
  "Musical",
  "Mystery",
  "Romance",
  "Sci-Fi (Science Fiction)",
  "Sport",
  "Thriller",
  "War",
  "Western",
];

const languageOptions = [
  "Tamil",
  "English",
  "Hindi",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Telugu",
  "Bengali",
  "Punjabi",
  "Urdu",
  "Spanish",
  "Korean",
  "Mandarin Chinese",
  "Arabic",
  "Portuguese",
  "Russian",
  "Japanese",
  "German",
  "Javanese",
  "Wu (Shanghainese)",
  "Turkish",
  "French",
  "Others",
];

const appendData = (data) => {
  const serverData = new FormData();
  for (var key in data) {
    serverData.append(key, data[key]);
  }
  return serverData;
};

const DealsDone = [
  {
    "Sr.No.": 1,
    Seller: "Book My Show ",
    TITLE: "NIGHT DRIVE",
    GENRE: "Thriller",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 80,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal1,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 2,
    Seller: "Book My Show ",
    TITLE: "SPACEWALKER",
    GENRE: "Adventure/Drama",
    OriginalLanguage: "RUSSIAN",
    RightsLicensed: "Hindi",
    Duration: 100,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal2,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 3,
    Seller: "Book My Show ",
    TITLE: "HEROIC LOSERS",
    GENRE: "Drama, Comedy",
    OriginalLanguage: "SPANISH",
    RightsLicensed: "Hindi",
    Duration: 115,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal3,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 4,
    Seller: "Book My Show ",
    TITLE: "THE HEIST OF THE CENTURY",
    GENRE: "Crime/Thriller",
    OriginalLanguage: "SPANISH",
    RightsLicensed: "Hindi",
    Duration: 114,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal4,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 5,
    Seller: "Book My Show ",
    TITLE: "GIRL WITH A BRACELET",
    GENRE: "COURT ROOM THRILLER",
    OriginalLanguage: "FRENCH",
    RightsLicensed: "Hindi",
    Duration: 95,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal5,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 6,
    Seller: "Book My Show ",
    TITLE: "SHORTA",
    GENRE: "ACTION THRILLER",
    OriginalLanguage: "DANISH",
    RightsLicensed: "Hindi",
    Duration: 108,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal6,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 7,
    Seller: "Book My Show ",
    TITLE: "RUN, HIDE, FIGHT",
    GENRE: "Action, Thriller",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 109,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal7,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 8,
    Seller: "Book My Show ",
    TITLE: "I REMEMBER YOU",
    GENRE: "Horror Thriller",
    OriginalLanguage: "ICELANDIC",
    RightsLicensed: "Hindi",
    Duration: 105,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal8,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 9,
    Seller: "Book My Show ",
    TITLE: "THE SUNLIT NIGHT",
    GENRE: "Romance/Drama",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 81,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal9,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 10,
    Seller: "Book My Show ",
    TITLE: "THE TUNNEL",
    GENRE: "Disaster",
    OriginalLanguage: "NORWEGIEN",
    RightsLicensed: "Hindi",
    Duration: 105,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal10,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 11,
    Seller: "Book My Show ",
    TITLE: "FEAR OF RAIN",
    GENRE: "Horror, Thriller",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 92,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal11,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 12,
    Seller: "Book My Show ",
    TITLE: "MARTYRS LANE",
    GENRE: "Horror",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 96,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal12,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 13,
    Seller: "Book My Show ",
    TITLE: "32 MALASANA STREET",
    GENRE: "Horror",
    OriginalLanguage: "SPANISH",
    RightsLicensed: "Hindi",
    Duration: 104,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal13,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 14,
    Seller: "Book My Show ",
    TITLE: "HOW TO MAKE OUT",
    GENRE: "Comedy",
    OriginalLanguage: "FRENCH",
    RightsLicensed: "Hindi",
    Duration: 98,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal14,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 15,
    Seller: "Book My Show ",
    TITLE: "GATECRASH",
    GENRE: "Thriller",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 90,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal15,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 16,
    Seller: "Book My Show ",
    TITLE: "ASSASSINS",
    GENRE: "Documentary",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 109,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal16,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  // {
  //  "Sr.No.": 17,
  //  "Seller": "Book My Show ",
  //  "TITLE": "GIRL WITH A BRACELET",
  //  "GENRE": "COURT ROOM THRILLER",
  //  "OriginalLanguage": "FRENCH",
  //  "RightsLicensed": "Hindi",
  //  "Duration": 95,
  //  "AgreementPeriod": "19\/04\/2023 Till 19\/04\/30 ",
  //  "Years": "7 years",
  //  "Buyer": "I TAP - Entertainment ",
  //  "images": Deal17,
  // },
  {
    "Sr.No.": 18,
    Seller: "Book My Show ",
    TITLE: "HATING GAME ",
    GENRE: "Romcom",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 102,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal17,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 19,
    Seller: "Book My Show ",
    TITLE: "THROUGH THE GLASS DARKLY",
    GENRE: "Thriller",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 103,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal18,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 20,
    Seller: "Book My Show ",
    TITLE: "INTO THE DARKNESS",
    GENRE: "Drama/War",
    OriginalLanguage: "Danish/German",
    RightsLicensed: "Hindi",
    Duration: 152,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal19,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 21,
    Seller: "Book My Show ",
    TITLE: "BIGGEST FAN",
    GENRE: "Comedy ",
    OriginalLanguage: "French ",
    RightsLicensed: "Hindi",
    Duration: 91,
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal20,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 22,
    Seller: "Book My Show ",
    TITLE: "The Trip ",
    GENRE: "Comedy",
    OriginalLanguage: "English, Italian, Spanish, Greek , Turkish",
    RightsLicensed: "Hindi",
    AgreementPeriod: "19/04/2023 Till 19/04/2030 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal21,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 23,
    Seller: "Thirukumaran Entertainment",
    TITLE: "Maayavan",
    GENRE: "Action",
    OriginalLanguage: "Telugu",
    RightsLicensed: "international dubbing rights",
    Duration: 121,
    AgreementPeriod: "03-01-2023 till 04-01-2028",
    Years: "5 years",
    Buyer: "BONGO HOLDINGS PTE LTD.",
    images: Deal22,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 24,
    Seller: "Time Ads Entertainment.",
    TITLE: "Varaal",
    GENRE: "Political Drama",
    OriginalLanguage: "Malayalam",
    RightsLicensed: "Telugu Dubbig rights",
    Duration: 120,
    AgreementPeriod: "Perpetuity",
    Years: "99 years",
    Buyer: "Sun TV Network Ltd",
    images: Deal23,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 25,
    Seller: "AHA( Arha Media & Broadcasting Private Limited)",
    TITLE: "Bhanumathi and Ramakrishna",
    GENRE: "Romance/Drama",
    OriginalLanguage: "Telugu",
    RightsLicensed: "international dubbing rights",
    Duration: 93,
    Years: "3 years",
    Buyer: "BONGO HOLDINGS PTE LTD.",
    images: Deal24,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 26,
    Seller: "AHA( Arha Media & Broadcasting Private Limited)",
    TITLE: "Emoji",
    GENRE: "Romance/Drama",
    OriginalLanguage: "Telugu",
    RightsLicensed: "international dubbing rights",
    Duration: 224,
    Years: "3 years",
    Buyer: "BONGO HOLDINGS PTE LTD.",
    images: Deal25,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 27,
    Seller: "AHA( Arha Media & Broadcasting Private Limited)",
    TITLE: "Three Roses",
    GENRE: "Comedy/Drama",
    OriginalLanguage: "Telugu",
    RightsLicensed: "international dubbing rights",
    Duration: 280,
    Years: "3 years",
    Buyer: "BONGO HOLDINGS PTE LTD.",
    images: Deal26,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 28,
    Seller: "AHA( Arha Media & Broadcasting Private Limited)",
    TITLE: "Shit Happens",
    GENRE: "Comedy",
    OriginalLanguage: "Telugu",
    RightsLicensed: "international dubbing rights",
    Duration: 100,
    Years: "3 years",
    Buyer: "BONGO HOLDINGS PTE LTD.",
    images: Deal27,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 29,
    Seller: "AHA( Arha Media & Broadcasting Private Limited)",
    TITLE: " 11th hour",
    GENRE: "Drama/Thriller",
    OriginalLanguage: "Telugu",
    RightsLicensed: "international dubbing rights",
    Duration: 200,
    Years: "3 years",
    Buyer: "BONGO HOLDINGS PTE LTD.",
    images: Deal28,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
];

const allRights = [
  { value: "Audio", label: "Audio" },
  { value: "Digital Posters", label: "Digital Poster" },
  { value: "Making", label: "Making" },
  { value: "Digital", label: "Digital" },
  { value: "Satellite", label: "Satellite" },
  { value: "Audio", label: "Theatrical" },
  { value: "Digital Posters", label: "Overseas Theatrical" },
  { value: "Digital Posters", label: "Other" },
  { value: "Digital", label: "Non Theatrical" },
  { value: "Satellite", label: "Dubbing" },
  { value: "NFT", label: "Remake" },
  { value: "Audio", label: "Travel" },
  { value: "Satellite", label: "Title" },
  { value: "NFT", label: "NFT" },
];

const videoSlides = [
  {
    video: "https://youtu.be/0Q9enxLuu3s?si=EGtvmuBArN7Frl9o",
  },
  {
    video: "https://youtu.be/tDf8NO48WZc?si=DYoMVmKsQDPYoXJM",
  },
];

const MovieDetails = [
  {
    "S.No": 1,
    "Movie Title": "Aadhav",
    Year: 2022,
    Language: "Kannada",
    Genre: "Drama",
    "Movie synopsis":
      "Aadhav an earnest boy, dreams of becoming a famous actor.But his father doesn't like the idea of him getting into the film industry because Aadhav is a bright and intelligent student.",
    "Production House": "KNK Studios",
    "Producer Name": "Kavil Nandakumar",
    "Available Rights": "All Rights",
    "Trailer Link": "https://youtu.be/oqyHyyQGqA8",
  },
  {
    "S.No": 2,
    "Movie Title": "Alexa",
    Year: 2023,
    Language: "Kannada",
    Genre: "Action,suspense,Thriller",
    "Movie synopsis":
      "Moves Around A Murder Of A Newly Married Girl Which Opens Up An Path For Investigation By A Female Lead Which Later Turns Around To A Very Thrilled Incidents Which Has Occurred Between Male And Female Lead Also Interconnected With The Murder And Opens Up A New Aspects Which Takes A Turn And Reveals How Powerfully Pharmaceutical Industry Has Grown And How Cleverly Our Male Lead Has Utilised It To His Own Plan And Creates Incidents Totally It Opens As A Murder Mystery And Gives Lead To A Master Plan Using World Most Powerful Drug With His Intelligence And Also Reveals Most Unknown Facts About Unpublished Drugs",
    "Production House": "Shri Bhuvanesh Productions",
    "Producer Name": "V Chandru",
    "Available Rights": "Hindi and Overseas right",
    "Trailer Link": "https://www.youtube.com/watch?v=eGAWEIVqRsQ",
  },
  {
    "S.No": 3,
    "Movie Title": "Eppo Kalyanam",
    Year: 2021,
    Language: "Tamil",
    Genre: "Drama,Romance",
    "Production House": "Chowdeshwari Productions",
    "Producer Name": "Irudayaraj",
    "Available Rights": "All Rights except Music",
    "Trailer Link":
      "https://www.facebook.com/BravoHDMovies/videos/eppo-kalyanam-official-trailer-livingstonevishwaranjth-kumarsowmiya-new-tamil-mo/309487837780297/",
  },
  {
    "S.No": 4,
    "Movie Title": "My Dream Girl",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Drama,Romance",
    "Movie synopsis":
      "A mistake done by “THE ALMIGHTY” shatters the life of 3 young chaps (Jeeva/Pavithra/Bala) .Jeeva left with no one falls into depression. He then decides to end up his life. Bala and pavi comes back as souls to save him and bring him out of depression and make him marry a girl of their choice. The story travels on the main theme “Butterfly Effect”",
    "Production House": "MSN globle creations",
    "Producer Name": "Thiru.VELLAY SETHU",
    "Available Rights": "All rights",
    "Trailer Link": "//vimeo.com/815555294/ pass -MDG2023",
  },
  {
    "S.No": 5,
    "Movie Title": "Netizen",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Thriller, Suspense",
    "Movie synopsis":
      "In Current trend teenagers are using mobiles and gadgets.This leads their life in a wrong way more over it kills them.This movie all about tells the reason behind it and solution",
    "Production House": "VJ Flicks",
    "Producer Name": "Karthick Selvaraj",
    "Available Rights": "All rights",
    "Trailer Link": "https://vimeo.com/744884905-netizen",
  },
  {
    "S.No": 6,
    "Movie Title": "Gavanam",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Thriller and Drama",
    "Movie synopsis":
      "This film deals with Terrorism with an unpredictable twist and turn. How Terrorism, which is a threat to the world countries, use the money and the desires of the people of that country to destroy their own nation, what the patriots of every nation are doing is suppressing terrorism and how the family is protected after the torture the terrorist who entered, causes to his family members. This film shows how careful one is when it comes to the security of a nation.",
    "Production House": "Mind Fram Production",
    "Producer Name": "K Pramila",
    "Available Rights": "All rights except Hindi",
    "Trailer Link":
      "https://drive.google.com/file/d/1tWmX4yoVqY-z9kU0_Ske2l2GuV585pCz/view?usp=drive_link",
  },
  {
    "S.No": 7,
    "Movie Title": "EE pattanakke Yenaagide",
    Year: "Under Producrtion",
    Language: "Kannada",
    Genre: "Drama",
    "Movie synopsis":
      "Achyutha a full time gambler looses money in cricket betting, how he recovers all his losses by conning other people.",
    "Production House": "RAVITHEJO STUDIOS",
    "Producer Name": "RITESH JOSHI & RAVI SUBBA RAO",
    "Available Rights": "All rights",
    "Trailer Link": "https://www.youtube.com/watch?v=OMLVua-uPOY",
  },
  {
    "S.No": 8,
    "Movie Title": "Kasiminte Kadal",
    Year: "Under Producrtion",
    Language: "Malayalam",
    Genre: "Drama",
    "Movie synopsis":
      "Kasiminte kadal movie is based on the acclaimed novel by Anees Salim, one of the outstanding storytellers in contemporary Indian writing. Uprooted from a bustling city, Kasim, the 12 year old protagonist is replanted in his father's home town where he struggles to cope with his new life. He reluctantly make friends with Bilal, a boy who lives in the orphanage run by the local mosque. Together, they embark on clandestine adventures while his ailing father-whose last wish is to die listening to the sea he has grown up by and written books about- rediscovers people from his childhood by accident. But his father's death unsettles the boy's life again and he finds himself grappling with altogether unexpected challenges. Lyrical and haunting, sharply funny and achingly sad, Kasiminte Kadal is a masterful tale of love, friendship and family from one of our most compelling filmmakers.",
    "Production House": "J K Productions",
    "Producer Name": "Jaykumar T S",
    "Available Rights": "All rights",
    "Trailer Link":
      "https://drive.google.com/file/d/1Ldgt4zBsy8K03TscSg0SxqzEc9Kna8yE/view?usp=drive_link",
  },
  {
    "S.No": 9,
    "Movie Title": "Arumadithu Kombu",
    Year: 2023,
    Language: "Tamil  ",
    Genre: "Action",
    "Movie synopsis":
      "AwardWinner The one who immeasurably blessed with mother, father, love and place. Unfortunately he loses everything in few days and comes to the point of ending his life,and what happened to him form the crux of this emotional rural drama.",
    "Production House": " CRE8IVE ACADEMY OF DANCE",
    "Producer Name": "THANGADURAI",
    "Available Rights": "All rights",
    "Trailer Link": "https://www.imdb.com/title/tt28489796/",
  },
  {
    "S.No": 10,
    "Movie Title": "Cutting Shop",
    Year: 2022,
    Language: "Kannada",
    Genre: "Comedy",
    "Movie synopsis":
      "The Movie Revolves Around A Film Editor Who Loses Interest In His Studies At An Early Stage And Develops An Interest Towards Film Editing. Film Depicts His Journey Of Becoming A Sucessful Film Editor Amidst All Hurdles Of The Society And The Film Industry In A Comical Way. The Film Carries A Message That One Should Do What Makes Him Happy Rather Than Following The General Rules Of The World.",
    "Production House": "Young Thinkers Films",
    "Producer Name": "Gurpur K Umesh, Ganesh K Aithal",
    "Available Rights": "All rights except kannada and music ",
    "Trailer Link": "https://www.youtube.com/watch?v=hB4co_zDyc0",
  },
  {
    "S.No": 11,
    "Movie Title": "Gowri",
    Year: 2022,
    Language: "Malayalam",
    Genre: "Suspens,Horror",
    "Movie synopsis":
      "The Story Is About A Group Of Youngsters Who Got A Passion For Filmmaking. It Is Upon Joy, Gouri, And Ajay Who Visits A Bungalow For The Sake Of A Film And Their Fearful And Dangerous Circumstances. Gouri Is Missing From The Next Day Of Their Arrival. The Bungalow House Is Said To Be Haunted. What Might'Ve Happened To Her? Can Ajay And Joy Rescue Her? Did The Spirits Attack Gouri? The Plot Thickens With Such Questions. The Movie Got A Good Script Includinga Lot Of Twists And Turns To Keep Up Suspense Till The End. Archana Sushilan Is The Protagonist In Gouri. The Movie Was Produced For A- Star Creations By Kannan Shekharen And Been Written And Directedby Sujith Vinayak.",
    "Production House": "A Stars Kreation",
    "Producer Name": "Kanna Sekharen",
    "Available Rights": "All rights",
    "Trailer Link": "https://www.youtube.com/watch?v=v6i2Ye25pJo",
  },
  {
    "S.No": 12,
    "Movie Title": "Suliyam",
    Year: 2022,
    Language: "Tamil",
    Genre: "Crime, Thriller",
    "Movie synopsis":
      "Aparna was killed in a mysterious way. Jeeva Tried to find out the cause behind Aparna’s murder. With his effort, Jeeva came to know that a similar kind of crime was the cause behind the murders that happened and he also sniffed Gowtham’s involvement in all the cases in some or the other way. The climax of the story is how Jeeva solve all the Mysterious with the spiritual Time Travel technique as an Ultimate Weapon.",
    "Production House": "Dheeran Film Productions",
    "Producer Name": "Dheeran Film Productions",
    "Available Rights": "All rights",
    "Trailer Link":
      "https://drive.google.com/file/d/1xmh4cSieyPey0maRdGFoHzM7ZbdxtKmR/view?usp=drive_link",
  },
  {
    "S.No": 13,
    "Movie Title": "Priyamudan Priya ",
    Year: 2023,
    Language: "Tamil",
    Genre: "Psycho Thirller",
    "Movie synopsis":
      "This story is about an incident which happens between 12.00 a.m to 5.00 a.m ( Play within 5 Hours ) It’s about a girl PRIYA (our heroin) who is living with her only sister whose husband is working in abroad and has two children. This girl is working as a Radio Jockey in RADIO MIRCHI FM for a program named PRIYAMUDAN PRIYA PRIYAMAINA PRIYA , which is the title of this movie. It’s a very famous program because of her sweet and husky voice, she has plenty of crazy fans. One of her crazy fan is our hero, who loves her very madly for her voice and lovely talk. Today it is her last program as she is getting married and leaving the town forever. This is unbearable for that guy. So, he decides to put this program completely under his control and make her to share this program, only with him, to show her how crazy he is about her and to know how much she loved and care about him. This psycho (our hero) first takes hostage of her sister and children in their house and makes a deal with her (our heroine) that if she stops the program or informs anyone, he will kill them. His game begins from there. This story will totally be demonstrated in screenplay about how she’ll save her sister and children, simultaneously without stopping the program.",
    "Production House": "Golden Glory Movies",
    "Producer Name": "J.Sujith",
    "Available Rights": "All Rights except Music rights",
    "Trailer Link": "https://youtu.be/S93MWbS2cTk",
  },
  {
    "S.No": 14,
    "Movie Title": "Swasame",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Drama/Romance",
    "Movie synopsis":
      "The movie Hero resides in a village called Palladam, Thiruppur District. After he finished his college, he started preparing to write TNPSC public service exam",
    "Production House": "Karia Kaliamman Pictures",
    "Producer Name": "R. Thangavel",
    "Available Rights": "All rights",
    "Trailer Link": "https://www.youtube.com/watch?v=LyK9GBEQO6o",
  },
  {
    "S.No": 15,
    "Movie Title": "Seyon",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Sci-fi thriller",
    "Movie synopsis":
      "Vijay's Girl Friend is abducted by unknown person. He call the vijay and started threat him. Seyon is a Sci-fi Thriller written and directed by pradeep",
    "Production House": "White Town Films",
    "Producer Name": "A.R.Rajesh",
    "Available Rights": "All rights",
    "Trailer Link": "https://moviewood.io/v/sKo8LQBFF2",
  },
  {
    "S.No": 16,
    "Movie Title": "Veerapuram 220",
    Year: 2021,
    Language: "Tamil",
    Genre: "Thriller",
    "Movie synopsis":
      "The Local Area Guys In Veerapuram Finding The Route Cause Of Unidentified Accidents In Their Home Town. This Story Is Based On True Incident",
    "Production House": "Subham Cine Creations",
    "Producer Name": "Sundarraj Ponnusamy ",
    "Available Rights": "All rights",
    "Trailer Link": "https://www.youtube.com/watch?v=S9LPVxZZkOE&t=3s",
  },
  {
    "S.No": 17,
    "Movie Title": "Mariguddada",
    Year: 2023,
    Language: "Kannada",
    Genre: "Action and Drama",
    "Movie synopsis":
      "A fictional story set in 1994, atop a hill Marigudda. A Group of bearded men headed by Hulia are killing and robbing people. Madesha sketches a plot to put an end to this.",
    "Production House": "D J Prakash Cini productions",
    "Producer Name": "R Chandrakanth",
    "Available Rights": "All rights except Audio and theatrical",
    "Trailer Link": "https://www.youtube.com/watch?v=m4G1a4g0jtI",
  },
  {
    "S.No": 18,
    "Movie Title": "Maayavan",
    Year: 2017,
    Language: "Tamil",
    Genre: "science Fiction",
    "Movie synopsis":
      "Kumarana, a policeman, investigate a series of mysterious murders that seems to be the work of a serial killer. However, the case gets more complicated as he unravels the killer's pattern.",
    "Production House": "Thirukumaran Entertainment",
    "Producer Name": "C. V. Kumar",
    "Available Rights": "Bangaladesh Rights ",
    "Trailer Link": "https://www.youtube.com/watch?v=pSSF1-nZVMI",
  },
  {
    "S.No": 19,
    "Movie Title": "Niyathi",
    Year: 2022,
    Language: "Tamil",
    Genre: "Action",
    "Movie synopsis":
      "When Private Detective chezhiyan finds solution for others problem ,he commits an accident and struck with a problem when he tries to find a solution for it, it reveals some other problem which took",
    "Production House": "Genius Film Company",
    "Producer Name": "C Naveen Kumar",
    "Available Rights": "All rights",
    "Trailer Link": "https://vimeo.com/811747405- Pass-niyathi123",
  },
  {
    "S.No": 20,
    "Movie Title": "Thirumbipaar",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Heist Crime Thriller",
    "Movie synopsis":
      'A first of its kinf of crime thriller in the history of Indian cinema based on the war tactic of "shadow Walk" used centuries ago to defeat enemies on the battefield',
    "Production House": "Pavi Vidhya Lakshmi productions",
    "Producer Name": "B. Giri",
    "Available Rights": "All rights",
    "Trailer Link": "https://youtu.be/eP2JBXzI5_w",
  },
  {
    "S.No": 21,
    "Movie Title": "Light House",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Crime, Thriller",
    "Movie synopsis": "A Man with a dark mission",
    "Production House": "Phoenix Films International",
    "Producer Name": "B Ananthlakshmi",
    "Available Rights": "All rights",
    "Trailer Link":
      "https://vimeo.com/manage/videos/740297562-Password : lhps01",
  },
  {
    "S.No": 22,
    "Movie Title": "Kadhal Pudhiyadhy",
    Year: "Under Producrtion",
    Language: "Tamil",
    Genre: "Drama",
    "Production House": "Achus Dream Media",
    "Producer Name": "S Soukath Ali",
    "Available Rights": "All rights",
  },
  {
    "S.No": 23,
    "Movie Title": "Suraapanam",
    Year: 2023,
    Language: "Telugu",
    Genre: "Comedy",
    "Movie synopsis":
      "Things take an unexpected twist when a young man shiva had bottel of Suraapanam Drink,Thinking as alcochol and he turn into old, youn and child in a day everyday and life happen after that is comedic Hilarious.Parvathi is the love interest of shiva in this thrilling comedy entertainment.",
    "Production House": "Akhil Bhavya Creations",
    "Producer Name": "Matta Madhu Yadhav",
    "Available Rights": "All rights except Telugu",
    "Trailer Link": "https://www.aha.video/movie/surapanam",
  },
];

const StackCarosuel = [
  {
    "Sr.No.": 1,
    imageId: 49,
    Seller: "Book My Show ",
    TITLE: "THE SUNLIT NIGHT",
    GENRE: "Romance/Drama",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 81,
    AgreementPeriod: "19/04/2023 Till 19/04/30 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal9,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 2,
    imageId: 51,
    Seller: "Book My Show ",
    TITLE: "THE TUNNEL",
    GENRE: "Disaster",
    OriginalLanguage: "NORWEGIEN",
    RightsLicensed: "Hindi",
    Duration: 105,
    AgreementPeriod: "19/04/2023 Till 19/04/30 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal10,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 3,
    imageId: 36,
    Seller: "Book My Show ",
    TITLE: "HOW TO MAKE OUT",
    GENRE: "Comedy",
    OriginalLanguage: "FRENCH",
    RightsLicensed: "Hindi",
    Duration: 98,
    AgreementPeriod: "19/04/2023 Till 19/04/30 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal14,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
  {
    "Sr.No.": 4,
    imageId: 43,
    Seller: "Book My Show ",
    TITLE: "RUN, HIDE, FIGHT",
    GENRE: "Action, Thriller",
    OriginalLanguage: "ENGLISH",
    RightsLicensed: "Hindi",
    Duration: 109,
    AgreementPeriod: "19/04/2023 Till 19/04/30 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal7,
    wallet: "CK78unCp5adQdBb468UHw22hLjYS3vy5hZDVyEfr8mNg",
  },
  {
    "Sr.No.": 5,
    imageId: 48,
    Seller: "Book My Show ",
    TITLE: "THE HEIST OF THE CENTURY",
    GENRE: "Crime/Thriller",
    OriginalLanguage: "SPANISH",
    RightsLicensed: "Hindi",
    Duration: 114,
    AgreementPeriod: "19/04/2023 Till 19/04/30 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal4,
    wallet: "EFdJ2j8DDCL1xvMnGxNMA8qjbYFw29SXYVnypEYBaAub",
  },
  {
    "Sr.No.": 6,
    imageId: 45,
    Seller: "Book My Show ",
    TITLE: "SPACEWALKER",
    GENRE: "Adventure/Drama",
    OriginalLanguage: "RUSSIAN",
    RightsLicensed: "Hindi",
    Duration: 100,
    AgreementPeriod: "19/04/2023 Till 19/04/30 ",
    Years: "7 years",
    Buyer: "I TAP - Entertainment ",
    images: Deal2,
    wallet: "Ds1aqvFX9vUjxTTw8qXZ39S8AKNG7AAskFUViwEuqNqs",
  },
];

const PaidRights = [
  {
    Aadhav: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Alexa: {
      AudioRights: "N/A",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "N/A",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "N/A",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Eppo Kalyanam": {
      AudioRights: "N/A",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "My Dream Girl": {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Netizen: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Gavanam: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "N/A",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "EE pattanakke Yenaagide": {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Kasiminte Kadal": {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Aramudaitha kombu": {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Cutting Shop": {
      AudioRights: "N/A",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Gowri: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Suliyam: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Priyamudan Priya": {
      AudioRights: "N/A",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Swasame: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Seyon: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Veerapuram 220": {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Mariguddada: {
      AudioRights: "N/A",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Maayavan: {
      AudioRights: "N/A",
      SatelliteRights: "N/A",
      DigitalRights: {
        Tamil: "N/A",
        Kannada: "N/A",
        Malayalam: "N/A",
        Telugu: "N/A",
        Hindi: "N/A",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "N/A",
        International: "N/A",
      },
      OverseasRights: "YES",
      InternationalDubbing: "N/A",
      TravelRights: "N/A",
      DubbingRights: "N/A",
      GamingRights: "N/A",
      MetaverseRights: "N/A",
      RemakeRights: "N/A",
      DialectRights: "N/A",
      SubtitleRights: "N/A",
      CableRights: "N/A",
      CommercialEstablishment: "N/A",
      NonExclusiveRights: "N/A",
      ExclusiveRights: "N/A",
    },
    Niyathi: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Thirumbipaar: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Light House": {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    "Kadhal Pudhiyadhy": {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "YES",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "YES",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
    Suraapanam: {
      AudioRights: "YES",
      SatelliteRights: "YES",
      DigitalRights: {
        Tamil: "YES",
        Kannada: "YES",
        Malayalam: "YES",
        Telugu: "N/A",
        Hindi: "YES",
      },
      TheatricalRights: {
        Regional: "N/A",
        National: "YES",
        International: "YES",
      },
      OverseasRights: "YES",
      InternationalDubbing: "YES",
      TravelRights: "YES",
      DubbingRights: "YES",
      GamingRights: "YES",
      MetaverseRights: "YES",
      RemakeRights: "YES",
      DialectRights: "YES",
      SubtitleRights: "YES",
      CableRights: "YES",
      CommercialEstablishment: "YES",
      NonExclusiveRights: "YES",
      ExclusiveRights: "YES",
    },
  },
];

const AllainceCred = [
  {
    title: "Better Invest",
    images: betterInvest,
  },
  // {
  //   title: 'Guardian Link',
  //    images:gaurdainlink,
  // },
  {
    title: "Kirlaw",
    images: kirlaw,
  },
  {
    title: "Brand Change",
    images: brandchange,
  },
  {
    title: "Startup India",
    images: startup,
  },
];

const ContentBuyer = [
  {
    title: "Jio Cinema",
    images: Jio,
  },
  {
    title: "Aha",
    images: Aha,
  },
  {
    title: "Amazon Prime Video",
    images: Prime,
  },
  {
    title: "Disney Hotstar",
    images: Disney,
  },
  {
    title: "Book my Show",
    images: Book,
  },
  {
    title: "Bongo",
    images: Bongo,
  },
  {
    title: "Zee5",
    images: Zee5,
  },
];

const MarketrightName = [
  { rights: "Audio" },
  { rights: "Satellite" },
  { rights: "Tamil Nadu Digital" },
  { rights: "Karnataka Digital" },
  { rights: "Kerala Digital" },
  { rights: "Andhra Pradesh & Telangana Digital" },
  { rights: "North India Digital" },
  { rights: "Regional Theatrical" },
  { rights: "National Theatrical" },
  { rights: "International Theatrical" },
  { rights: "Overseas" },
  { rights: "International Dubbing" },
  { rights: "Travel" },
  { rights: "Dubbing" },
  { rights: "Gaming" },
  { rights: "Metaverse" },
  { rights: "Remake" },
  { rights: "Dialect" },
  { rights: "Subtitle" },
  { rights: "Cable" },
  { rights: "Commercial Establishment" },
  { rights: "Non-Exclusive" },
  { rights: "Exclusive" },
];

const BlogsList = [
  {
    images: Event1,
    date: 2021,
    title:
      "Producerbazaar Expands into Kerala: Collaborates with 40+ Malayalam Producers for Digital Distribution",
    content: `Producerbazaar, a pioneering NFT Movie Marketplace based in Chennai, is making waves as it ventures into Kerala's vibrant film industry. In a strategic partnership with over 40 Malayalam producers, spearheaded by Mr. G Suresh Kumar, President of the Kerala Film Chamber of Commerce (KFCC), Producerbazaar aims to revolutionize the global distribution of Malayalam films through digital channels, ensuring secure transactions and maximizing revenue streams for production houses.
    The collaboration comes at a time when the entertainment landscape is witnessing a rapid shift towards digital platforms and emerging technologies. By leveraging Producerbazaar's expertise in NFT technology and digital distribution, Malayalam producers can now reach audiences worldwide, tapping into new markets and unlocking untapped potential.
    Mr. G Suresh Kumar, a key figure in the Kerala film industry, expressed his enthusiasm for the partnership, highlighting its potential to drive growth and innovation. He emphasized the importance of embracing digital initiatives to stay ahead in an increasingly competitive market, urging more industry events and collaborations to further elevate Kerala's film industry on the global stage.
    The support of industry veterans like Mr. G S Vijayan and Mr. Kalliyoor Sasi underscores the significance of this partnership and its potential to reshape the future of cinema in Kerala. With their guidance and expertise, Producerbazaar and the KFCC are poised to spearhead a new era of digital distribution and monetization in the Malayalam film industry.
    Producerbazaar's extensive catalog, boasting over 1000 movies already onboarded, sets the stage for further expansion and innovation. By harnessing NFT technology, Producerbazaar aims to bring international films to India while creating new revenue streams for production houses. This initiative aligns with emerging entertainment concepts such as Blockchain and Movieindustry 3.0, signaling a paradigm shift in the way films are produced, distributed, and consumed.
    As the entertainment landscape continues to evolve, partnerships like these exemplify the industry's commitment to embracing innovation and driving progress. Through collaboration, technology adoption, and forward-thinking strategies, Producerbazaar and its partners are poised to shape the future of cinema, not just in Kerala, but across India and beyond.
    Stay tuned to Producerbazaar for more updates on their groundbreaking initiatives and collaborations as they continue to push the boundaries of digital distribution and content monetization in the film industry. `,
  },

  {
    images: Event7,
    date: 2023,
    title:
      "Producerbazaar Receives Recognition at IFFI Goa 2023: A Milestone in Redefining Media Tech",
    content: `
      Producerbazaar, a trailblazer in reshaping the entertainment industry through innovative media technology, recently achieved a significant milestone by garnering recognition at the prestigious International Film Festival of India (#IFFI) Goa 2023. Selected to showcase at the esteemed Tech Media Startup Expo, this nod from the Goa Government underscores Producerbazaar's commitment to driving innovation and excellence in the media tech space.
      The inclusion of Producerbazaar in the Tech Media Startup Expo at IFFI Goa serves as a testament to their visionary approach and pioneering solutions. This recognition not only validates their efforts but also provides a platform to showcase their transformative technologies to a global audience of industry leaders, filmmakers, and enthusiasts.
      Prior to this milestone, Producerbazaar has been actively engaged in fostering collaborations and nurturing talent within the startup ecosystem in Goa. Their participation in events like the GOA TECH SUMMIT and Startup Goa has allowed them to showcase their expertise, lead insightful sessions, and forge valuable connections with fellow innovators and stakeholders.
      At IFFI Goa 2023, Producerbazaar seized the opportunity to reconnect with past contacts, engage in meaningful discussions on future strategies, and acknowledge the collective dedication of the industry towards Goa's startup growth. Their enthusiasm and commitment to driving positive change in the media tech landscape were palpable throughout the event, reflecting their unwavering passion for innovation and excellence.
      The recognition received at IFFI Goa 2023 not only validates Producerbazaar's contributions to the media tech industry but also serves as a catalyst for further growth and expansion. As they continue to push the boundaries of technology and creativity, Producerbazaar remains committed to driving innovation, fostering collaboration, and shaping the future of entertainment.`,
  },

  {
    images: Event6,
    date: 2022,
    title: `Unlocking the Power of Intellectual Property: Insights from ProducerBazaar's IPR Monetizing Strategic Event in Bengaluru`,
    content: `ProducerBazaar.com, a trailblazer in the realm of content monetization and protection, recently hosted an enlightening event in Bengaluru on December 7th. The IPR Monetizing Strategic Event, held at the prestigious Dr. Ambareesh Auditorium in Chamrajpet, served as a platform to educate and empower content creators on effectively monetizing and safeguarding their creative works. With insights from industry veterans and innovators, the event offered invaluable guidance on navigating the ever-evolving landscape of intellectual property rights (IPR) and content commerce.
    Key industry figures, including acclaimed filmmaker Girish Kasaravalli, shared their expertise on essential aspects such as funding mechanisms, IP rights protection, and innovative content strategies. Their insights provided attendees with practical knowledge and actionable strategies to maximize the value of their intellectual property assets.
    Vijay Dingari, CBO of NFT Creator Economy Private Limited (NCEPL), highlighted the company's mission to create a transparent media business ecosystem. By connecting artists and end-users through their platform, NCEPL aims to revolutionize the way content is traded and consumed. With the trust of over 2000 film creators and ambitious plans to introduce a B2B Marketplace and enter the Metaverse, NCEPL is at the forefront of leveraging NFT and Blockchain technology to reshape the content industry.
    Co-Founder of ProducerBazaar.com, GK Tirunavukarasu, emphasized the platform's commitment to innovation and disruption in content commerce. With the support of perspectives from Angel Investor Mr. Varada Rajan Krishna and IPR attorney Jacob Kurian, the event underscored the importance of securing and monetizing creators' stories in an increasingly digital and interconnected world.
    The event not only provided attendees with valuable insights but also fostered meaningful connections and collaborations within the industry. By bringing together creators, investors, and legal experts, ProducerBazaar.com facilitated a holistic discussion on the challenges and opportunities in content monetization and protection. As the entertainment landscape continues to evolve, events like the IPR Monetizing Strategic Event play a crucial role in equipping content creators with the knowledge and tools they need to thrive. By embracing innovation and leveraging emerging technologies, the industry can unlock new avenues for revenue generation and storytelling.`,
  },
  {
    images: Event2,
    date: 2022,
    title: `NFDC Partners with NFT Creator Economy to Assess Tamil Film Industry's Potential`,
    content: `The National Film Development Corporation (NFDC), under the leadership of Director Rajesh Khanna, has embarked on a pioneering initiative to evaluate the status and potential of the Tamil film industry. Partnering with NFT Creator Economy Pvt. Ltd, in collaboration with Producerbazaar, India's leading NFT Movie marketplace, NFDC aims to conduct a comprehensive survey aimed at gathering insights from key stakeholders and compiling a detailed report on the industry's health and prospects.
      The initiative involves engaging with producers, distributors, exhibitors, and other industry stakeholders through a structured questionnaire. By soliciting input from various segments of the Tamil film fraternity, NFDC seeks to gain a holistic understanding of the challenges and opportunities facing the industry, with the ultimate goal of strengthening Tamil cinema.
      Director Rajesh Khanna emphasized the crucial role of producers as the backbone of the industry, highlighting the importance of their support in driving industry growth and sustainability. By commissioning this survey, NFDC aims to identify areas for improvement, address revenue leaks, and uncover potential avenues for industry development.
      GK Tirunavukarasu, Co-Founder of Producerbazaar, expressed optimism about the initiative's potential to boost revenue and foster industry growth. With the support of technology and innovative platforms like Producerbazaar, the Tamil film industry can leverage digital solutions to streamline processes, track movie releases, and enhance revenue generation. Also emphasized the pivotal role of technology in shaping the future of the entertainment industry. By leveraging NFT and Blockchain technology, the initiative aims to provide transparency and accountability while empowering creators and stakeholders.
      The comprehensive report compiled through this survey will serve as a valuable resource for industry stakeholders, providing insights into the current state of the Tamil film industry and outlining strategies for future growth and development. By fostering collaboration and innovation, NFDC, NFT Creator Economy, and Producerbazaar are committed to driving positive change and ensuring the continued success of Tamil cinema.`,
  },

  {
    images: Event5,
    date: 2023,
    title:
      "Hyderabad Hosts Pathbreaking Event: 100+ Telugu Film Producers Unite for Copyrights & IP Rights in Cinema",
    content: `Hyderabad, known as the heart of the Telugu film industry, recently witnessed a historic gathering of over 100 film producers. The occasion? A pioneering session on 'Copyrights & IP Rights in Cinema' hosted by Producerbazaar.com in collaboration with the Telugu Film Chamber of Commerce. 
    This event marked a significant stride towards propelling the Telugu film industry into the digital age, with an emphasis on comprehensive monetization through innovative rights management strategies. The event, graced by industry stalwarts and visionaries, served as a platform for robust discussions and insightful exchanges. Producers, directors, distributors, and other key stakeholders converged to explore the evolving landscape of intellectual property rights in the realm of cinema.
    At the forefront of this transformative dialogue was Producerbazaar.com, India's leading media tech company, renowned for its cutting-edge solutions in tracking and monetizing film rights. Through its innovative platforms and services, Producerbazaar.com has been instrumental in revolutionizing the industry's approach to content creation and commercialization.
    The session delved into various aspects of copyrights and intellectual property rights, shedding light on their significance in today's digital-centric entertainment ecosystem. Participants gained valuable insights into best practices for safeguarding their creative assets, navigating complex legal frameworks, and maximizing revenue streams through strategic rights management.
    In an era where content consumption patterns are rapidly evolving, the need for robust rights management solutions has never been more pronounced. Producerbazaar.com's suite of tools and services equips filmmakers and producers with the means to assert control over their content and harness its full commercial potential across diverse platforms and formats.
    As the Telugu film industry continues to expand its footprint globally, initiatives like these play a pivotal role in fostering a culture of innovation and sustainability. By empowering creators with the knowledge and resources to protect and monetize their intellectual property, we lay the foundation for a thriving ecosystem that benefits all stakeholders involved. The overwhelming response to the 'Copyrights & IP Rights in Cinema' session underscores the industry's collective commitment to embracing change and driving progress. With collaboration, innovation, and a shared vision for the future, the Telugu film industry is poised to chart new heights of success in the digital era.`,
  },
  {
    images: Event4,
    date: 2023,
    title:
      "Introducing a New Avenue for Film Producers to Generate Revenue: Producerbazaar.com and BetterInvest.club Partnership",
    content: `Film production is a dynamic and often challenging industry, where securing financing is crucial for bringing creative visions to life. Recognizing the need for innovative funding solutions, Producerbazaar.com, in collaboration with BetterInvest.club, is proud to introduce a novel initiative aimed at supporting production houses in their quest for financial stability and project completion.
      The partnership between Producerbazaar.com and BetterInvest.club brings forth a unique funding model tailored specifically for production houses. This initiative addresses a common pain point faced by producers, wherein revenue from sale contracts with OTTs, audio labels, web series, tele serials, and satellite TV networks often arrives in installments, causing financial strain and hampering project progress.
      To alleviate this challenge, Producerbazaar.com and BetterInvest.club offer swift financing solutions, with funds disbursed within 7 to 10 working days, enabling production houses to meet immediate cash flow needs without requiring collateral. This expedited funding process empowers producers to efficiently manage their working capital requirements, ensuring timely completion and monetization of ongoing projects.
      The initiative extends its support across various languages, including Telugu, Malayalam, Kannada, Marathi, and Tamil, with financing options available for a diverse range of productions. With a focus on empowering production houses across the regional film industry, this collaboration aims to foster growth and sustainability in the filmmaking ecosystem.
      By providing access to timely financing, Producerbazaar.com and BetterInvest.club enable producers to focus on what they do best – creating compelling and impactful content. This initiative not only facilitates smoother project execution but also unlocks new avenues for revenue generation, driving industry innovation and resilience.
      As the entertainment landscape continues to evolve, partnerships like these underscore the importance of collaboration and adaptation in navigating industry challenges. Producerbazaar.com and BetterInvest.club are committed to supporting the growth and success of production houses, empowering them to thrive in an ever-changing market environment. Together, let's embark on a journey towards a more vibrant, sustainable, and prosperous future for filmmakers and creators alike.`,
  },
];

export {
  slickImages,
  appendData,
  StackCarosuel,
  sellerList,
  BlogsList,
  MarketrightName,
  videoSlides,
  ContentBuyer,
  PaidRights,
  AllainceCred,
  DealsDone,
  allRights,
  languageOptions,
  genreOptions,
  countries,
  productionHouses,
  testimonials,
  HomeCards,
  rightList,
  FAQ,
  BuyerList,
  Events,
  mediaMentions,
};
