import React, { useEffect, useRef } from "react";
import crypto from "crypto-js";
import Logo from "../Assets/logo-removebg-preview-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import { fetch_verify_signature } from "../Utils/Services/CommonService.js";

// Function to load script and append in DOM tree.
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.log("razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RazorUI = ({
  orderId,
  keyId,
  keySecret,
  currency,
  amount,
  setDisplayRazorpay,
  paymentFailed,
  email,
  name,
  usertype,
  sellerId,
  setShowConfirmation,
  savedType,
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  const navigate = useNavigate();

  // To load razorpay checkout modal script.
  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      paymentFailed();
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  // informing server about payment
  const handlePayment = async (status, orderDetails = {}) => {
    const paymentId = orderDetails?.paymentId?.current
      ? orderDetails?.paymentId?.current
      : null;
    const signature = orderDetails?.signature ? orderDetails?.signature : null;
    const paymentStatus = status === "succeeded" ? 1 : 0;
    try {
      await fetch_verify_signature(
        orderDetails?.orderId,
        paymentId,
        signature,
        paymentStatus,
        savedType,
        sellerId
      );
      if (status === "succeeded") {
        setDisplayRazorpay(false);
        if (name !== "" && email !== "") {
          navigate("/signupconfirmation", {
            state: {
              name: name,
              email: email,
              order: orderId,
              usertype: usertype,
              paymentId: paymentId,
              paymentStatus: paymentStatus,
              savedType: savedType,
              sellerId: sellerId,
            },
          });
        } else {
          setShowConfirmation(true);
        }
      } else {
        paymentFailed();
      }
    } catch (error) {
      console.error("Error occurred while handling payment:", error);
      paymentFailed();
    }
  };

  const options = {
    key: keyId, // key id from props
    amount, // Amount in lowest denomination from props
    currency, // Currency from props.
    name: "Producer Bazaar", // Title for your organization to display in checkout modal
    image: Logo, // custom logo  url
    order_id: orderId,
    // redirect: true,
    // callback_url:'https://producerbazaar.com/signupconfirmation/',
    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      console.log("succeeded");
      console.log(response);
      paymentId.current = response.razorpay_payment_id;

      // Most important step to capture and authorize the payment. This can be done of Backend server.
      const succeeded =
        crypto
          .HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret)
          .toString() === response.razorpay_signature;

      // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
        const modalElement = document.querySelector(".razorpay-container");
        if (modalElement) {
          modalElement.remove();
        }
        handlePayment("succeeded", {
          orderId,
          paymentId,
          signature: response.razorpay_signature,
        });
      } else {
        const modalElement = document.querySelector(".razorpay-container");
        if (modalElement) {
          modalElement.remove();
        }
        handlePayment("failed", {
          orderId,
          paymentId: response.razorpay_payment_id,
          signature: null,
        });
      }
    },
    escape: false,
    modal: {
      confirm_close: true,
      backdropclose: false,
      // this is set to true, if we want confirmation when clicked on cross button.
      // This function is executed when checkout modal is closed
      // There can be 3 reasons when this modal is closed.
      ondismiss: async (reason) => {
        paymentFailed();
        const modalElement = document.querySelector(".razorpay-container");
        if (modalElement) {
          modalElement.remove();
        }

        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
        if (reason === undefined) {
          console.log("cancelled");
          paymentFailed();
          const modalElement = document.querySelector(".razorpay-container");
          if (modalElement) {
            modalElement.remove();
          }
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === "timeout") {
          console.log("timedout");
          paymentFailed();
          const modalElement = document.querySelector(".razorpay-container");
          if (modalElement) {
            modalElement.remove();
          }
        }
        // Reason 3 - When payment gets failed.
        else {
          console.log("failed");
          paymentFailed();
          const modalElement = document.querySelector(".razorpay-container");
          if (modalElement) {
            modalElement.remove();
          }
        }
      },
    },
    // This property allows to enble/disable retries.
    // This is enabled true by default.
    retry: {
      enabled: false,
    },
    timeout: 900, // Time limit in Seconds
    theme: {
      color: "", // Custom color for your checkout modal.
    },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, []);

  return null;
};

export default RazorUI;
