import React, { useContext, useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import "./NavBar.css";
import Logo from '../../Assets/logo-removebg-preview-removebg-preview.png';
import { Avatar, Box, Button, InputAdornment, TextField} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey,  } from "@solana/web3.js";
import { UserContext } from "../../Context/UserContext";

function NavBar() {
  
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const Auth = localStorage.getItem('BazaarUserAuth');
  const userType = localStorage.getItem('BazaarUserType');
  const [publicKey, setPublicKey] = useState(null);
  const [WalletConnected , setWalletConnected] = useState(true);
  const { disconnect,  } = useWallet();
  const { data } = useContext(UserContext);
  const handleApiKey = (apiKeyString) => {
    const publicKey = new PublicKey(apiKeyString);
    setPublicKey(publicKey);
  };

  useEffect(() => {
    const apiPublicKey = process.env.REACT_APP_PUBLICKEY;
    handleApiKey(apiPublicKey);
  }, []);

  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });


  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 1025 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  window.addEventListener("scroll", function() {
    const navBar = document.querySelector(".NavBar");

    // Check if navBar exists before accessing its properties
    if (navBar) {
        const offset = navBar.offsetHeight;
    
        if (window.scrollY >= offset) {
            navBar.classList.add("sticky");
        } else {
            navBar.classList.remove("sticky");
        }
    }
});


  const gotoLink = (item) =>{
    navigate(`${item}`);
    setMenuOpen(false);
  }


  const GotoProfile = () =>{
    if(Auth){
      if(userType === 'Buyer'){
        navigate('/buyer')
      }
      else{
        navigate('/seller')
      }
  }
}

  const connectWallet = async () => {
    console.log("Connected to wallet:", publicKey?.toBase58());
    setWalletConnected(true);
  };


  const DisconnectWallet = async () => {
    try {
      await disconnect();
      console.log("Disconnected from wallet");
      setWalletConnected(false);
    } catch (error) {
      console.error("Error disconnecting from wallet:", error);
    }
  };

  const handleSearch = () =>{
    if(searchText !== ''){
      localStorage.setItem('searchText' , searchText)
      navigate('/movieresults');
      }
      setSearchText('');
  }

  const handleChangeSearchText = (event) =>{
    setSearchText(event.target.value);
  }

  return (
    <Box className='NavBar' >
    <header className="header">
      <div className="header__content">
        <Link to="/" className="header__content__logo">
         <img src={Logo} alt='header' style={{objectFit:'contain' , width:280 , height:98 , mixBlendMode:'multiply' , verticalAlign:'middle'}} />
         <span className="beta-label">Beta</span>
        </Link>
        <nav
          className={`${"header__content__nav"} 
          ${menuOpen && size.width < 1025 ? `${"isMenu"}` : ""} 
          }`}
        >
          <ul>
            <li onClick={()=>gotoLink('/')}>
              <Link >Home</Link>
            </li>
            <li  onClick={()=>gotoLink('/about')}>
              <Link>About</Link>
            </li>
            <li  onClick={()=>gotoLink('/marketplace')}>
              <Link>MarketPlace</Link>
            </li>
            <li>
            <TextField
            size="small"
            placeholder="  search"
            value={searchText}
            onChange={handleChangeSearchText}
            InputProps={{
            endAdornment: <InputAdornment position="start" onClick={handleSearch} sx={{cursor:'pointer'}}>
              <Search sx={{color:'#fff'}} />
            </InputAdornment>,
            sx:{
            borderRadius:'20px' ,
             width:{lg:'30ch', md:'24ch', sm:'30ch', xs:'20ch'} ,
             borderColor: menuOpen && size.width < 970 ? '#fff' : '#000'},
             borderWidth:'2px',
            }}
            variant="outlined"
            />
            </li>
            <li>
              {
                !Auth ? 
            <Button variant='contained' 
            onClick={()=>gotoLink('/signin')}
            className="signinBtn"
            sx={{
              borderRadius:'15px',
            bgcolor: menuOpen && size.width < 970 ? '#46344E' :'#46344E', 
            fontWeight:600 ,  
            width:'15ch' , 
            color: menuOpen && size.width < 970 ? '#fff' : '#fff' ,
            boxShadow:0,  
            ':hover':{
              bgcolor: menuOpen && size.width < 970 ? '#46344E' :'#fff', 
            boxShadow:0,
            color: menuOpen && size.width < 970 ? '#fff' : '#46344E' }}}>
            Sign In</Button>
                :
                <Box display='flex' flexDirection='row' gap={3}>
                  {
                  WalletConnected ? 
                <Button variant='contained' 
                sx={{ bgcolor:'#46344E', color: '#fff', borderRadius:'15px' , boxShadow:0,  
                ':hover':{
                bgcolor: '#fff', 
                color: '#46344E' } 
                }} 
                onClick={DisconnectWallet}>Disconnect</Button>
                  :
                  <Button
                   variant='contained' 
                  sx={{ bgcolor:'#46344E', color: '#fff', borderRadius:'15px' , boxShadow:0,  
                  ':hover':{
                  bgcolor: '#fff', 
                  color: '#46344E' } 
                  }} 
                  onClick={connectWallet}>Connect Wallet</Button>
                  }
                <Avatar 
                onClick={GotoProfile}
                alt="Producer Bazaar" src={data?.userProfile ?  data?.userProfile : ''} sx={{ marginLeft:'5px' , cursor:'pointer'}}/>
              </Box>
              }
            </li>
          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
    </Box>
  );
}

export default NavBar;