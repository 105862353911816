import { Box, Grid,  Link, Typography } from '@mui/material'
import React from 'react'
import FacebookIcon from '../../Assets/Footer/icons8-facebook-96.png' ;
import TwitterIcon from '../../Assets/Footer/icons8-twitter-96.png' ;
import YouTubeIcon from '../../Assets/Footer/icons8-youtube-96.png' ;
import LinkedInIcon from '../../Assets/Footer/icons8-linkedin-96.png' ;
import InstagramIcon from '../../Assets//Footer//icons8-insta-96.png' ;
import { useNavigate } from 'react-router-dom';
import PrivacyPDF from '../../Assets/Document/Privacy Policy_New.pdf';
import TermsPDF from '../../Assets/Document/Terms and Conditions_New.pdf';


export default function Footer() {

  const navigate = useNavigate();

  const handleClickOpenNameEdit = (id) =>{
    navigate(`/about#${id}`);
   
    const scrollTimeout = setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
   }



  return (
    <Box bgcolor='#fff' boxShadow={1}>
      <Box p={5}>

        <Grid container spacing={{lg:2 , md:2 , sm:4 , xs:4}} justifyContent='center'>

          <Grid item xs={12} sm={6} md={3} lg={3} display='flex' justifyContent='center'>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' , textAlign:'left'}}>
           <Typography variant="h3" color="#5B5561" fontWeight={550} fontSize={21} marginBottom='20px'>Quick Links</Typography>
           <li><Link href="/" underline="none" color="#707070"><Typography fontSize={17}>Home</Typography></Link></li>  
            <li><Link href="/about" underline="none" color="#707070"><Typography fontSize={17}>About Us</Typography></Link></li>
            <li><Link href="/marketplace" underline="none" color="#707070"><Typography fontSize={17}>MarketPlace</Typography></Link></li>                      
            <li><Link onClick={()=>handleClickOpenNameEdit('FAQSection')} underline="none" color="#707070"><Typography fontSize={17} mb={{lg:0 , md:0, sm: 2 , xs: 3}}>FAQ</Typography></Link></li>            
            </ul>
          </Grid>    


          <Grid item xs={12} sm={6} md={3} lg={3} display='flex' justifyContent='center'>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' , textAlign:'left'}}>
          <Typography variant="h3" color="#5B5561" fontWeight={550} fontSize={21}  marginBottom='20px'>Company</Typography>
          <li><Link onClick={()=>handleClickOpenNameEdit('contactform')} underline="none" color="#707070"><Typography fontSize={17}>Contact Us</Typography></Link></li>
          <li><Link href={PrivacyPDF} target='_blank' rel="noopener noreferrer" underline="none" color="#707070"><Typography fontSize={17}>Privacy Policy</Typography></Link></li>
          <li><Link href={TermsPDF} target='_blank' rel="noopener noreferrer" underline="none" color="#707070"><Typography fontSize={17}>Terms of Use</Typography></Link></li></ul>
          </Grid> 


            {/* <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography variant="h3" color="#5B5561" fontWeight={550} fontSize={21}  marginBottom='20px'>Subscribe Newsletter</Typography>
            <Box display='flex' flexDirection='row'>
            <TextField 
            id="outlined-basic" 
            size='small'
            variant="outlined"
            fullWidth
            label='Email'
            InputProps={{
                sx:{
                    borderRadius:0
                }
            }}
            />
               <Button variant='contained' 
               sx={{bgcolor:'#FFC61A', fontWeight:600 , color:'#46344E', borderRadius:0 , width:150 , boxShadow:0 , ':hover':{boxShadow:0 ,color:'#46344E',bgcolor:'#FFC61A'  }}}>
                Subscribe
                </Button>
            </Box>
            </Grid>  */}

            <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box>
            <Typography variant="h3" color="#5B5561" fontWeight={550} fontSize={21}  marginBottom='20px'>Social Links</Typography>
            <a style={{color:'#2d2d2d'}} href="http://www.youtube.com/@producerbazaar4202" target="_blank" rel="noopener noreferrer">
            <img src={YouTubeIcon} alt='YT' style={{height:50, width:50, marginRight:5,  objectFit:'contain', mixBlendMode:'multiply'}} />
            </a>

            <a style={{color:'#2d2d2d'}} href="https://www.facebook.com/ProducerBazaar/" target="_blank" rel="noopener noreferrer">
            <img src={FacebookIcon} alt="FB" style={{height:50, width:50, marginRight:5, objectFit:'contain', mixBlendMode:'multiply'}} />
            </a> 

            <a style={{color:'#2d2d2d'}} href="https://twitter.com/ProducerBazaar" target="_blank" rel="noopener noreferrer">
            <img src={TwitterIcon} alt='tw' style={{height:50, width:50, marginRight:5, objectFit:'contain', mixBlendMode:'multiply'}} />
            </a> 

            <a style={{color:'#2d2d2d'}} href="https://www.linkedin.com/company/76520510/" target="_blank" rel="noopener noreferrer">
            <img src={LinkedInIcon} alt="ln" style={{height:50, width:50, marginRight:5, objectFit:'contain', mixBlendMode:'multiply'}} />
            </a>  

            <a style={{color:'#2d2d2d'}} href="https://www.instagram.com/producerbazaar/" target="_blank" rel="noopener noreferrer">
            <img src={InstagramIcon} alt='in' style={{height:50, width:50, marginRight:5, objectFit:'contain', mixBlendMode:'multiply'}} />
            </a>    
            </Box>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography variant="h3" color="#5B5561" fontWeight={550} fontSize={21}  marginBottom='20px'>Download our app</Typography>
            <img src={PlayStore} style={{marginRight:10}} alt='img'></img>
            <img src={AppStore} style={{marginRight:10}} alt='img'></img>
            </Grid>  */}

          </Grid>                
   

      </Box>

        <Grid container mt={{lg:5 , md:5, sm:3 , xs:2}} p={1} spacing={1}>
        <Grid item xs={12} textAlign='center' justifyContent='center' display='flex'>
        <Typography className='copyright'>
        © ProducerBazaar {new Date().getFullYear()}. All Rights Reserved.
        </Typography>
        </Grid>
        {/* <Grid item xs={12} md={6} textAlign='center' px={2} justifyContent={{lg:'end', md:'end', sm:'center', xs:'center'}} display='flex'>
        <Link  underline="none" >
            <Typography sx={{color:"#46344E"}}>  Crafted with care by GoGreenverz Tech Solutions</Typography>
            </Link>
        </Grid> */}
        </Grid>

    </Box>
  )
}
