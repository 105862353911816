import {
  update_movie_details,
  get_seller_movie,
  get_buy_movies,
  add_buy_movies,
  get_movies_by_rights,
  get_transaction_movies,
  get_active_movies,
  add_movie_details,
  get_movies_rights,
  get_movie_rights_status,
  get_admin_active_movies,
  FusionId,
  get_random_movies,
} from "../BaseAPI/API";
import { apiGETRequest, apiPOSTRequest } from "../apiRequest";

const fetch_update_movie_details = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(update_movie_details, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_seller_movie = async (userId, movieId) => {
  const sendData = new FormData();
  sendData.append("sellerId", userId);
  sendData.append("movieId", movieId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_seller_movie, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_active_movies = async (
  movieId,
  activeMovieId,
  sellerId,
  rightsId,
  movieRights,
  movieTitle,
  userId
) => {
  const sendData = new FormData();
  sendData.append("movieId", movieId);
  sendData.append("activeMovieId", activeMovieId);
  sendData.append("sellerId", sellerId);
  sendData.append("rightsId", rightsId);
  sendData.append("movieRights", movieRights);
  sendData.append("movieTitle", movieTitle);
  sendData.append("userId", userId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_active_movies, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_admin_active_movies = async (rowsPerPage, pageNumber) => {
  const sendData = new FormData();
  sendData.append("movieId", "");
  sendData.append("activeMovieId", "");
  sendData.append("sellerId", "");
  sendData.append("rightsId", "");
  sendData.append("noOfData", rowsPerPage);
  sendData.append("pageNo", pageNumber);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(
      get_admin_active_movies,
      sendData
    );
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_buy_movies = async (userId, movieId, buyMovieId, rightsId) => {
  const sendData = new FormData();
  sendData.append("buyerId", userId);
  sendData.append("movieId", movieId);
  sendData.append("buyMovieId", buyMovieId);
  sendData.append("rightsId", rightsId);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_buy_movies, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_buy_movies = async (
  movieId,
  userId,
  userName,
  receipt,
  buyerAddress
) => {
  const sendData = new FormData();
  sendData.append("movieId", movieId);
  sendData.append("buyerId", userId);
  sendData.append("buyerName", userName);
  sendData.append("purchaseReceipt", receipt);
  sendData.append("buyerAddress", buyerAddress);
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(add_buy_movies, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_movies_by_rights = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_movies_by_rights, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_transaction_movies = async (
  movieId,
  buyMovieId,
  userId,
  rightsId
) => {
  const sendData = new FormData();
  sendData.append("movieId", movieId);
  sendData.append("buyMovieId", buyMovieId);
  sendData.append("buyerId", userId);
  sendData.append("fusionUserId", FusionId);
  sendData.append("rightsId", rightsId);
  try {
    const responseData = await apiPOSTRequest(get_transaction_movies, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_movie_details = async (data) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(add_movie_details, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_movies_rights = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_movies_rights, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_movie_rights_status = async (movieId) => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  sendData.append("movieId", movieId);
  try {
    const responseData = await apiPOSTRequest(
      get_movie_rights_status,
      sendData
    );
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_random_movies = async () => {
  const sendData = new FormData();
  sendData.append("fusionUserId", FusionId);
  try {
    const responseData = await apiPOSTRequest(get_random_movies, sendData);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  fetch_update_movie_details,
  fetch_get_movie_rights_status,
  fetch_get_random_movies,
  fetch_add_movie_details,
  fetch_add_buy_movies,
  fetch_get_active_movies,
  fetch_get_buy_movies,
  fetch_get_movies_by_rights,
  fetch_get_movies_rights,
  fetch_get_seller_movie,
  fetch_get_transaction_movies,
  fetch_get_admin_active_movies,
};
